/** @jsx jsx */
import { Box, Text, Flex, jsx } from 'theme-ui'

const TableOfContents = ({ dataTopics, dataActive, forwardRef }) => {
  return (
    <Box
      ref={forwardRef}
      sx={{ width: '164px', minWidth: '180px', position: 'sticky', top: '30%' }}
    >
      <Text
        sx={{
          fontSize: 14,
          lineHeight: '14px',
          fontWeight: 'extraBold',
          whiteSpace: 'nowrap',
          fontFamily: 'secondary',
          textTransform: 'uppercase'
        }}
      >
        Table of Contents
      </Text>
      <Flex sx={{ flexDirection: 'column' }}>
        {dataTopics?.map((data, i) => {
          return (
            <a
              key={data.id + i}
              href={`#${data.id}`}
              sx={{
                mt: '15px',
                fontFamily: 'secondary',
                fontWeight: 'extraBold',
                lineHeight: '14px',
                fontSize: 1,
                width: 'fit-content',
                pb: '4px',
                transition: '0.2s ease',
                borderBottom:
                  dataActive === data.id
                    ? '2px solid #2B38BD'
                    : '2px solid transparent'
              }}
            >
              {data.name}
            </a>
          )
        })}
      </Flex>
    </Box>
  )
}

export default TableOfContents
